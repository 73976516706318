
















import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import ServiceTable from "@/components/service/ServiceTable.vue";
import ServiceCreateDialog from "@/components/dialogs/ServiceCreateDialog.vue";
import SnackbarModule from "@/store/snackbarModule";
import Api from "@/api";
import { ServiceModel } from "@/api/generated";

const snackbarModule = getModule(SnackbarModule);

@Component({
  components: { ServiceCreateDialog, ServiceTable }
})
export default class ServiceOverview extends Vue {
  private tableRefreshKey = false;
  private createDialog = false;

  private onCreateService() {
    this.createDialog = true;
  }

  private async onDeleteService(service: ServiceModel) {
    if (!service.serviceId) {
      return;
    }

    try {
      await Api.ServiceService.apiServiceServiceIdDelete(service.serviceId);
      snackbarModule.setSnackbarMessage(`Service successfully deleted`);
    } catch {
      snackbarModule.setSnackbarMessage(`Failed to delete service`);
    } finally {
      this.tableRefreshKey = !this.tableRefreshKey;
    }
  }
}
