







































































































import { Component, PropSync, Ref, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { VForm } from "@/types/vForm";
import Validation from "@/mixins/validation";
import CountryAutocomplete from "@/components/common/country/CountryAutocomplete.vue";
import SnackbarModule from "@/store/snackbarModule";
import Api from "@/api";
import { ServiceUpsertModel } from "@/api/generated";

const snackbarModule = getModule(SnackbarModule);

@Component({ components: { CountryAutocomplete } })
export default class ServiceCreatetDialog extends Validation {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Ref() private readonly form!: VForm;

  private loading = false;
  private model: ServiceUpsertModel = {
    companyName: ""
  };

  @Watch("syncedDialog")
  private onDialogClose() {
    if (!this.syncedDialog) {
      return;
    }

    this.form?.reset();
  }

  private async onServiceCreate() {
    if (!this.form.validate()) {
      return;
    }

    try {
      this.loading = true;
      await Api.ServiceService.apiServicePost(this.model);
      snackbarModule.setSnackbarMessage("Service created");
      this.$emit("service-created");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to create service");
    } finally {
      this.loading = false;
      this.syncedDialog = false;
    }
  }
}
