


































import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { DataTableHeader } from "vuetify";
import nameof from "@/utility/nameof";
import { ServiceModel } from "@/api/generated";
import Api from "@/api";
import SnackbarModule from "@/store/snackbarModule";
import UserModule from "@/store/userModule";

const userModule = getModule(UserModule);
const snackbarModule = getModule(SnackbarModule);

@Component
export default class ServiceTable extends Vue {
  private loading = false;
  private items: Array<ServiceModel> = [];
  private headers: Array<DataTableHeader<ServiceModel>> = [
    { text: "", value: "actions", sortable: false },
    { text: "Company", value: nameof<ServiceModel>("companyName") },
    { text: "Address", value: nameof<ServiceModel>("address") },
    { text: "Licence Number", value: nameof<ServiceModel>("licenseNumber") },
    { text: "Start Date", value: nameof<ServiceModel>("startDate") },
    { text: "Tel Number", value: nameof<ServiceModel>("telephoneNumber") },
    { text: "Email", value: nameof<ServiceModel>("emailAddress") },
    {
      text: "Main Point Of Contact",
      value: nameof<ServiceModel>("mainPointOfContact")
    },
    { text: "Country", value: nameof<ServiceModel>("countryName") }
  ];

  private get agencyAdminRole() {
    return userModule.role == "AGM";
  }

  private async created() {
    await this.getServices();
  }

  private async getServices() {
    try {
      this.loading = true;
      const response = await Api.ServiceService.apiServiceGet();
      this.items = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to retrieve services");
    } finally {
      this.loading = false;
    }
  }

  private onUpdateService(service: ServiceModel) {
    if (!service.serviceId) {
      return;
    }

    this.$router.push({
      name: "Service Edit Details",
      params: { serviceId: service.serviceId }
    });
  }
}
